import React from 'react';
import { AppBar, Toolbar, Grid } from '@mui/material';
import logo from './logo.svg'; // Make sure to put your logo.svg in the src folder

function Header() {
  return (
    <AppBar position="static" style={{ backgroundColor: '#ffe217', height: '70px' }}>
      <Toolbar style={{ height: '100%' }}>
        <Grid container alignItems="center" style={{ height: '100%' }}>
          <Grid item xs={6} style={{ textAlign: 'left' }}>
            <img src={logo} alt="Logo" style={{ height: '20px', maxWidth: '100%' }} />
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  );
}

export default Header;
