import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Box, CssBaseline  } from '@mui/material';
import Header from './Header';
import IframeContainer from './pages/IframeContainer';
import ThankYou from './pages/thankyou';
import Footer from './Footer';

function App() {
  return (
    <Router>
      <CssBaseline />
      <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100%' }}>
        <Header />
        <Routes>
          <Route path="/" element={<IframeContainer />} />
          <Route path="/registration" element={<IframeContainer />} />
          <Route path="/thankyou" element={<ThankYou />} />
        </Routes>
        <Footer />
      </Box>
  </Router>
  );
}

export default App;