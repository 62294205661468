import React from 'react';
import { Box, Button, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

function ThankYou() {
  const navigate = useNavigate();

  const handleBackToMain = () => {
    navigate('/');
  };

  return (
    <Box sx={{ textAlign: 'center', p: 4, backgroundColor: '#EBE5D7', flex:1 }}>
      <CheckCircleOutlineIcon sx={{ fontSize: 60, color: 'green' }} />
      <Typography variant="h5" sx={{ my: 2 }}>
        Thank you for verifying your POHA House application with rentcard!
      </Typography>
      <Typography variant="body1" sx={{ mt: 1 }}>
        We received your data and will review it within 24 hours. 
      </Typography>
      <Typography variant="body1" sx={{ mt: 1 }}>
        You will then receive an update from us via mail.
      </Typography>
      <Typography variant="body1" sx={{ mt: 1 }}>
        If you have any questions in the meantime, do not hesitate to reach out via <a href="mailto:hey@pohahouse.com">hey@pohahouse.com</a>
      </Typography>
      
      <Button 
        variant="contained" 
        sx={{ mt: 3, color: '#363638', backgroundColor: '#FFE217', '&:hover': { backgroundColor: '#FFE217' }, }}
        onClick={handleBackToMain}
      >
        Back to homepage
      </Button>
    </Box>
  );
};

export default ThankYou;
